import React from 'react'
import PresetSentenceCreatePage from '../../../../components/pages/StarTutoring/PresetSentenceCreatePage'
import StarTutoringLayout from '../../../../components/pages/StarTutoring/StarTutoringLayout'

interface IProps {
  path: string,
  params: {
    id: string
  }
}

const Page = (props: IProps) => {
  return <StarTutoringLayout path={props.path}>
    <PresetSentenceCreatePage id={props.params.id} />
  </StarTutoringLayout>
}

export default Page
