import { navigate } from 'gatsby';
import { Button } from 'nzk-react-components';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { TextInput } from 'grommet';
import useData from '../data/PresetSentences/useData';
import BasicEditor from '../../../BasicEditor';
import { colors } from '../../../../theme'

const Wrapper = styled.div`
  padding-bottom: 90px;
  h1 {
    text-align: center;
    color: ${colors.indigo};
  }
`

const Form = styled.div`
  max-width: 450px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0,0,0,0.4);
  > * { margin-bottom: 10px; }
  > :last-child { margin-bottom: 0; }
`

const Field = styled.div`

`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`


interface IFormData {
  message: string
  title: string
}

const PresetSentenceCreatePage = (props: { id?: string }) => {
  const { fetchPresetSentence, updatePresetSentence, createPresetSentence } = useData()
  const [formData, setFormData] = useState<IFormData>({
    title: '',
    message: ''
  })

  useEffect(() => {
    if (props.id) {
      const fetch = async () => {
        const data: any = await fetchPresetSentence(props.id!)
        setFormData({
          title: data.title,
          message: data.message
        })
      }
      fetch()
    }
  }, [])

  const onSubmit = async () => {
    if (props.id) {
      const sentence = await updatePresetSentence(props.id, formData)
      if (sentence._id) {
        navigate(`/star-tutoring/preset-sentences`)
      }
    } else {
      const sentence = await createPresetSentence(formData)
      if (sentence._id) {
        navigate(`/star-tutoring/preset-sentences`)
      }
    }
  }

  const onCancel = () => {
    if (props.id) {
      navigate(`/star-tutoring/preset-sentences`)
    } else {
      navigate(`/star-tutoring/preset-sentences`)
    }
  }

  return <Wrapper>
    <h1>{props.id ? 'Edit' : 'Create'} preset sentence</h1>
    <Form>
      <Field>
        <div>Title</div>
        <TextInput
          value={formData.title}
          type='text'
          onChange={e => {
            const { value } = e.target
            setFormData(d => ({ ...d, title: value }))
          }}
          placeholder='Title'
        />
      </Field>
      <Field>
        <div>Message</div>
        <BasicEditor
          value={formData.message}
          onHTMLChange={html => setFormData(d => ({ ...d, message: html }))}
        />
      </Field>
      <Actions>
        <Button size='small' theme='confirm' disabled onClick={onCancel}>Cancel</Button>
        <Button size='small' theme='confirm' onClick={onSubmit}>{props.id ? 'Update' : 'Create'}</Button>
      </Actions>
    </Form>
  </Wrapper>
}

PresetSentenceCreatePage.defaultProps = {
  id: undefined
}

export default PresetSentenceCreatePage
